@font-face {
  font-family: 'Markup';
  src: local('Markup'), url(./font/Markup.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

html, body, #root {
  height: 100%
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.right-aligned-dropdown .dropdown-menu{
  left: auto;
  right: 0;
}

textarea {
  background-color: rgba(255, 255, 255, 0.3) !important;
  border-color: rgba(255, 255, 255, 0.3) !important;
}

.toolbox-select {
  /* position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  width: 50%;
  opacity: 0.8;
  align-self: end;
}

.markup-font, .markup-font.tooltip {
  font-family: 'Markup', cursive;
}